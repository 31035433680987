import { useQuery } from 'react-query'
import { useGqlClient, getFinishedPresentationsPerCluster, getFinishedPresentationsPerTrainer } from '@aposphaere/core-kit'
import { QueryOptions } from './utils'
import { FinishedPresentation } from '../useDataDashboardsDownload'
import { useCrmContext } from '../../contexts/crmContext'

type FinishedPresentationQueryProps = {
  options?: QueryOptions<FinishedPresentation[]>
  clusterId?: string
  date?: { from: string; to: string }
  enabled?: boolean
}

export const useFinishedPresentationsQuery = (props?: FinishedPresentationQueryProps) => {
  const gqlClient = useGqlClient()
  const { activeCluster } = useCrmContext()

  const variables = { date: props?.date, pharmacyClusterId: activeCluster.id ?? props?.clusterId }

  return useQuery(
    ['finished_presentations', `cluster-${variables.pharmacyClusterId}`, `dateRange-${JSON.stringify(variables.date)}`],
    async () => {
      const data = await gqlClient.request<{ finishedPresentationsPerCluster: FinishedPresentation[] }>(getFinishedPresentationsPerCluster, variables)
      return data?.finishedPresentationsPerCluster
    },
    { staleTime: Infinity, enabled: props?.enabled ?? true, ...props?.options },
  )
}

type FinishedPresentationQueryTrainerProps = {
  options?: QueryOptions<FinishedPresentation[]>
  date?: { from: string; to: string }
  enabled?: boolean
  trainerId?: number
}

export const useFinishedPresentationsQueryTrainer = (props?: FinishedPresentationQueryTrainerProps) => {
  const gqlClient = useGqlClient()

  const variables = { date: props?.date, trainer_id: props?.trainerId }

  return useQuery(
    ['finished_presentations', `trainer-${variables.trainer_id}`, `dateRange-${JSON.stringify(variables.date)}`],
    async () => {
      const data = await gqlClient.request<{ finishedPresentationsPerTrainer: FinishedPresentation[] }>(getFinishedPresentationsPerTrainer, variables)
      return data?.finishedPresentationsPerTrainer
    },
    { staleTime: Infinity, enabled: props?.enabled ?? true, ...props?.options },
  )
}
