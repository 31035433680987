import { endOfQuarter, startOfQuarter } from 'date-fns'
import React, { createContext, useContext, useMemo, useState } from 'react'
import { useDashboardSummaryPerCluster } from '../hooks/useDashboardSummaryPerCluster'
import { useGermanyDashboardSummary } from '../hooks/useGermanyDashboardSummary'
import { DataForDownload, useDataDashboardsDownload } from '../hooks/useDataDashboardsDownload'
import { WHOLE_GERMANY_ID } from '../pages/DashboardPage/constants'

export type DateType = {
  startDate: Date
  endDate: Date
}

export type DataForGraphType = {
  label: string
  cancelledCount: number
  bookedCount: number
  visitCount: number
  trainingDoneCount: number
}

export enum DashboardStructureType {
  GENERAL = 'GENERAL',
  SUMMARY_CARDS = 'SUMMARY_CARDS',
  GRAPH = 'GRAPH',
  SUMMARY_PER_PROJECT = 'SUMMARY_PER_PROJECT',
}

export type SummaryPerProjectDataType = {
  trainingCompletedCount: number
  id: string
  name: string
  projectPharmacyCount: number
  pharmaciesReceivedTrainingCount: number
  bookedFromTomorrow: number
  cancelledCount: number
  consecutiveTrainingCount: number
  participantCount: number
  averageDuration: number
  projectGoal?: number
  totalTrainingCount?: number | string
  gapTrainingCount?: number | string
  averageTotalTrainingCount?: number | string
  averageTrainingGoal?: number | string
  averageGapTrainingCount?: number | string
  goalAchievement?: number | string
  isWithinQuarter?: boolean
  bookedTimePerProject: string
}

export interface DashboardsContextType {
  [DashboardStructureType.GENERAL]: {
    dates: DateType
    setDates: React.Dispatch<React.SetStateAction<DateType>>
    clusterSelected: string
    setClusterSelected: React.Dispatch<React.SetStateAction<string>>
  }
  [DashboardStructureType.SUMMARY_CARDS]: {
    appointmentsCompleted: number
    appointmentsCreatedByAgent?: number
    appointmentsBooked: number
    visitsCompleted: number
    visitsBooked: number
    averageAppointmentCount: number | string
    bookedPharmaciesCount: number
    pharmaciesCount: number
    trainedPharmaciesCount: number
    bookedForFuturePharmaciesCount: number
    pharmaciesWithMultipleAppointmentsCount: number
    pharmaciesWithCancelledAppointmentsCount: number
    trainingsQuote: number
    pastTrainingSets: number
    pastRepeatedTrainingSets: number
    averageTrainingsPerDay: number | string
    totalSlots: number
    bookedSlots: number
    completedSlots: number
    averageSlotsPerAppointment: number | string
    averageSlotsPerDay: number | string
    totalParticipantCount: number
    averageParticipantsPerAppointment: number | string
    homeOfficeCount: number
    vacationCount: number
    privateCount: number
    repeatedTrainigsCount?: number
    meetingsCount: number
    sickCount: number
    vacantCount: number
    daysInFieldLeft: number
    totalWorkingDays: number
    totalDaysInField: number
    exitQuestionResponseRate: number
  }
  isLoading: boolean
  dataForDownload: DataForDownload
  [DashboardStructureType.GRAPH]?: DataForGraphType[]
  [DashboardStructureType.SUMMARY_PER_PROJECT]: SummaryPerProjectDataType[]
}

const initialDateState = {
  startDate: startOfQuarter(new Date()),
  endDate: endOfQuarter(new Date()),
}

export const DashboardContext = createContext<DashboardsContextType | null>(null)

const DashboardProvider: React.FC = ({ children }) => {
  const [clusterSelected, setClusterSelected] = useState('')
  const [dates, setDates] = useState(initialDateState)
  const wholeGermanySummaryData = useGermanyDashboardSummary(clusterSelected, dates)
  const summaryDataPerCluster = useDashboardSummaryPerCluster(clusterSelected, dates)
  const dataForDownload = useDataDashboardsDownload(clusterSelected, dates)

  const summaryData = clusterSelected === WHOLE_GERMANY_ID ? wholeGermanySummaryData : summaryDataPerCluster
  const value = useMemo(() => {
    return {
      [DashboardStructureType.GENERAL]: { clusterSelected, setClusterSelected, dates, setDates },
      ...summaryData,
      dataForDownload,
    }
  }, [clusterSelected, setClusterSelected, dates, setDates, summaryData, dataForDownload])

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>
}

export const useDashboardContext = () => {
  const context = useContext(DashboardContext)
  if (!context) {
    throw new Error("Provider not found: Attemting to use the dashboardContext, without it's provider")
  }
  return context
}

export default DashboardProvider
