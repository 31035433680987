import {
  Appointment,
  FinishedPresentationsResponse,
  formattedDateString,
  formattedTimeString,
  getFinishedPresentationsForAppointment,
  Project,
  parseUTC,
} from '@aposphaere/core-kit'
import { EditIcon, IconButton, IconButtonKind } from '@aposphaere/ui-components'
import React from 'react'
import { useAuthenticatedQuery } from '../../hooks/useAuthenticatedQuery'

import { ModalKind, useModal } from '../../contexts/modalContext'
import { useStatusesQuery } from '../../hooks/graphql'

type AppointmentsCardItemProps = {
  appointment: Appointment
  canEditDelete: boolean
  canEditAppointment: boolean
  projects: Project[]
  presentations?: FinishedPresentationsResponse[]
}

const AppointmentsCardItem: React.FunctionComponent<AppointmentsCardItemProps> = ({
  canEditAppointment,
  appointment,
  canEditDelete,
  projects,
  presentations = [],
}) => {
  // Set class depending on status appointment.kind === "pending" ? 'w-4/6' : 'w-5/6'
  const additionalCssClass = ''

  const { openModal } = useModal()
  const { data: statuses } = useStatusesQuery()
  const status = statuses?.forAppointments?.find((_status) => String(_status.id) === String(appointment?.status_id))
  const isCancelled = status?.isCancelled

  return (
    <li className="w-full px-2 2xl:px-4 border-b border-gray-400 last:border-0">
      <div className="grid grid-cols-7 xl:grid-cols-6 gap-2 w-full py-4">
        <div className="flex col-span-2 min-w-130px">
          <div className="text-base leading-6 font-medium whitespace-normal">
            {/* Date  */}
            <span className="font-medium">{appointment.date ? formattedDateString(parseUTC(appointment.date)) : ''}</span>
            <br />
            {/* Time  */}
            <span className="font-medium ">{appointment.date ? formattedTimeString(new Date(appointment.date)) : ''}</span>
          </div>
        </div>
        <div className="col-span-1 xl:hidden" />
        <div className={`flex ${additionalCssClass} col-span-3 items-center`}>
          <div className="text-base leading-6 font-medium">
            <span className="font-normal">
              {`${appointment.pharmacy?.name || ''} (ID:${appointment.pharmacy?.id || ''} / ${appointment.pharmacy?.okid || ''})`}
            </span>
            <br />
            <span className="font-normal">
              {`${appointment.pharmacy?.address.address_name || ''}, ${appointment.pharmacy?.address.zipcode || ''} ${
                appointment.pharmacy?.address.city || ''
              }.`}
            </span>
            <br />

            {projects.map((project, index) => (
              <div key={project.name} className="font-normal">
                {project.name}
                <br></br>
                {appointment.order_items.find((item) => item.project_id === +project.id)?.amount || ''} - Teilnehmer
                {index === projects.length - 1 ? '' : ','}
              </div>
            ))}
            {appointment.note && (
              <div className="flex font-normal">
                <span className="w-10">Notiz:</span>
                <p className="ml-1">{appointment.note}</p>
              </div>
            )}
            <div className="font-medium">
              <p className="">{appointment.creator?.name}</p>
              <div className="flex ">
                <span className="w-10 font-medium">ID:</span>
                <p className="ml-1">{appointment.creator?.id}</p>
              </div>
            </div>
            {presentations.length ? (
              <div className="flex flex-col font-normal">
                <p className="w-10 font-medium mt-1">Präsentationen:</p>
                <ul>
                  {presentations.map((presentation: FinishedPresentationsResponse) => {
                    const { time_spent } = presentation

                    const seconds = parseInt(time_spent, 10)
                    let time = ''
                    if (seconds > 3599) {
                      time = `Dauer der Schulung: ${Math.floor(seconds / 3600)} Stunde ${Math.floor(seconds / 60)} min`
                    } else {
                      time = `Dauer der Schulung: ${Math.round(seconds / 60)} min`
                    }
                    return (
                      <li className={'mt-1 flex flex-col'} key={presentation.id}>
                        <span className={'mb-1'}>{`${presentation?.project?.name || ''}`}</span>
                        <span className={'mb-1'}>{`${presentation.number_of_participants} - Teilnehmer`}</span>
                        <span className={'mb-1 border-b border-gray-300'}>{time}</span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            ) : null}
          </div>
        </div>

        {canEditDelete && (
          <>
            <div className="xl:flex hidden col-span-1 justify-end">
              {canEditAppointment && (
                <IconButton
                  kind={IconButtonKind.default}
                  icon={<EditIcon />}
                  onClick={() => openModal({ kind: ModalKind.AppointmentEdit, id: appointment.id })}
                />
              )}
            </div>
            <div className="flex xl:hidden col-span-1 justify-end">
              {canEditAppointment && (
                <IconButton
                  kind={IconButtonKind.default}
                  icon={<EditIcon dimension="30" />}
                  onClick={() => openModal({ kind: ModalKind.AppointmentEdit, id: appointment.id })}
                />
              )}
            </div>
          </>
        )}
      </div>
      {isCancelled && (
        <div className="flex w-full py-3 pl-4 pr-6 my-2 rounded-md bg-red-300 text-red-700 items-center">
          <span className="pl-4">{'Termin abgesagt'}</span>
        </div>
      )}
    </li>
  )
}

export const AppointmentsCardItemWithPresentation: React.FunctionComponent<AppointmentsCardItemProps> = (props) => {
  const { data } = useAuthenticatedQuery<{ finishedPresentationsForAppointment: FinishedPresentationsResponse[] }>(
    getFinishedPresentationsForAppointment,
    { appointmentId: props.appointment.id },
  )

  return <AppointmentsCardItem {...props} presentations={data ? data.finishedPresentationsForAppointment : []} />
}

export default AppointmentsCardItem
