import { formattedDateString, ILeg } from '@aposphaere/core-kit'
import { IconButton, IconButtonKind, MapMarkerHomeIcon, RouteModal, RouteModalItem, MapMarker, MapMarkerColor } from '@aposphaere/ui-components'
import React, { useCallback, useMemo, useEffect, useRef } from 'react'
import { useRoutePlanner } from '../../contexts/routePlannerContext'
import { useProjectsQuery } from '../../hooks/graphql'
import { useViewport } from '../../hooks/useViewPort'
import Fade from '../Transitions/Fade'
import { getDurationPerTraining } from './utils'
import { googleMapColors } from '../Map/constants'
import { addSeconds, format, isAfter, isEqual, subSeconds, parse } from 'date-fns'
import { getAppointmentRanking } from '../Map'
import { usePharmacyQuery } from '../../hooks/graphql'
import { useCalendarPanel } from '../CalendarPanelAppointment/CalendarPanelContext'
import { SidebarStatus } from '../../pages/StartPage/StartPage'

enum ITrafficColors {
  GREEN = 1,
  YELLOW = 2,
  RED = 3,
}

const TrafficLight = ({ color }: { color: ITrafficColors }) => {
  const colorMap = {
    [ITrafficColors.GREEN]: 'bg-green-500',
    [ITrafficColors.RED]: 'bg-red-700',
    [ITrafficColors.YELLOW]: 'bg-yellow-700',
  }
  const colorClass = colorMap[color]
  return <div className={`w-4 h-4 rounded-full absolute right-0 top-4 ${colorClass}`} />
}

const RouteColorMarker = ({ index }: { index: number }) => {
  const className = `h-full-route-modal w-1 border-l-2 border-map-${googleMapColors[index]} border-solid ml-3.5 pl-4`
  return <div className={className} />
}
interface Props {
  selectedPharmacyId: number | undefined
  setSelectedPharmacy: (id: number | undefined) => void
  setRouteForAppointment: React.Dispatch<React.SetStateAction<boolean>>
  handleSidebarToCalender: () => void
  sidebarStatus: SidebarStatus
  shouldUpdateDataItem: boolean
  setShouldUpdateDataItem: React.Dispatch<React.SetStateAction<boolean>>
}

export const CustomRouteModal: React.FC<Props> = ({
  selectedPharmacyId,
  setRouteForAppointment,
  handleSidebarToCalender,
  sidebarStatus,
  shouldUpdateDataItem,
  setShouldUpdateDataItem,
  setSelectedPharmacy,
}) => {
  const {
    setSelectedDay,
    setRouteResponse,
    routeResponse,
    selectedDay,
    sortedAppointmentsToday,
    showSelectedDayOverview,
    toggleShowSelectedDayOverview,
  } = useRoutePlanner()
  const { selectedOrderItems, selectedTime, setSelectedTime } = useCalendarPanel()
  const { data: projects } = useProjectsQuery()
  const { width } = useViewport()
  const { data: activePharmacy } = usePharmacyQuery(selectedPharmacyId)
  const filteredLegs = useMemo(() => routeResponse?.routes[0].legs.filter((leg: ILeg) => !!leg.duration.value) ?? [], [routeResponse])
  const distanceDataToFirstAppointment = filteredLegs[0]
  const durationToFirstAppountment = distanceDataToFirstAppointment?.duration?.value ?? 0

  const prevDataRef = useRef<any[]>([])
  const dataItems = useMemo(() => {
    if (sortedAppointmentsToday && activePharmacy && shouldUpdateDataItem && sidebarStatus.type === 'appointment-creation') {
      const appointmentData = sortedAppointmentsToday.map((app, index) => {
        const parsedDate = parse(app.date!, 'yyyy-MM-dd HH:mm:ss', new Date())
        const formattedDate = format(parsedDate, "EEE MMM dd yyyy HH:mm:ss 'GMT'xxx", { useAdditionalWeekYearTokens: false })
        const formDate = new Date(formattedDate)
        const appointmentStartDate = formDate
        const trainingDurationInSeconds = getDurationPerTraining(app.order_items, projects ?? [])
        const appointmentEndDate = addSeconds(appointmentStartDate, trainingDurationInSeconds)

        return {
          id: index,
          pharmacyName: app.pharmacy?.name,
          address: `${app.pharmacy?.address.zipcode} ${app.pharmacy?.address.address_name}`,
          appointmentDuration: `${format(appointmentStartDate, 'HH:mm')}-${format(appointmentEndDate, 'HH:mm')}`,
          trainingDurationInSeconds,
          appointmentStartDate,
          rank: getAppointmentRanking(appointmentStartDate),
        }
      })
      const formattedDate = format(selectedTime, "EEE MMM dd yyyy HH:mm:ss 'GMT'xxx", { useAdditionalWeekYearTokens: false })
      const formDate = new Date(formattedDate)
      const appointmentStartDate = formDate
      const trainingDurationInSeconds = getDurationPerTraining(selectedOrderItems, projects ?? [])
      const appointmentEndDate = addSeconds(appointmentStartDate, trainingDurationInSeconds)

      const activePharmacyData = {
        id: sortedAppointmentsToday.length,
        pharmacyName: activePharmacy.name,
        address: `${activePharmacy.address.zipcode} ${activePharmacy.address.address_name}`,
        appointmentDuration: `${format(appointmentStartDate, 'HH:mm')}-${format(appointmentEndDate, 'HH:mm')}`,
        trainingDurationInSeconds: selectedOrderItems ? trainingDurationInSeconds : 0,
        appointmentStartDate,
        rank: getAppointmentRanking(appointmentStartDate),
      }

      const combinedData = [...appointmentData, activePharmacyData]
      combinedData.sort((a, b) => (a.appointmentStartDate > b.appointmentStartDate ? 1 : -1))

      const newCombinedData = combinedData.map((item, index) => ({
        ...item,
        id: index,
        toNextAppointment: `${filteredLegs[index + 1]?.distance.text} / ${filteredLegs[index + 1]?.duration.text}`,
      }))

      prevDataRef.current = newCombinedData

      return newCombinedData
    } else if (sortedAppointmentsToday && shouldUpdateDataItem) {
      const updatedData = sortedAppointmentsToday.map((app, index) => {
        const parsedDate = parse(app.date!, 'yyyy-MM-dd HH:mm:ss', new Date())
        const formattedDate = format(parsedDate, "EEE MMM dd yyyy HH:mm:ss 'GMT'xxx", { useAdditionalWeekYearTokens: false })
        const formDate = new Date(formattedDate)
        const appointmentStartDate = formDate
        const trainingDurationInSeconds = getDurationPerTraining(app.order_items, projects ?? [])
        const appointmentEndDate = addSeconds(appointmentStartDate, trainingDurationInSeconds)
        const toNextAppointment = `${filteredLegs[index + 1]?.distance.text} / ${filteredLegs[index + 1]?.duration.text}`

        return {
          id: index,
          pharmacyName: app.pharmacy?.name,
          address: `${app.pharmacy?.address.zipcode} ${app.pharmacy?.address.address_name}`,
          appointmentDuration: `${format(appointmentStartDate, 'HH:mm')}-${format(appointmentEndDate, 'HH:mm')}`,
          toNextAppointment,
          trainingDurationInSeconds,
          appointmentStartDate,
          rank: getAppointmentRanking(appointmentStartDate),
        }
      })

      prevDataRef.current = updatedData

      return updatedData
    } else if (activePharmacy && !sortedAppointmentsToday) {
      const formattedDate = format(selectedTime, "EEE MMM dd yyyy HH:mm:ss 'GMT'xxx", { useAdditionalWeekYearTokens: false })
      const formDate = new Date(formattedDate)
      const appointmentStartDate = formDate
      const trainingDurationInSeconds = getDurationPerTraining(selectedOrderItems, projects ?? [])
      const appointmentEndDate = addSeconds(appointmentStartDate, trainingDurationInSeconds)
      const nextLeg = filteredLegs[1]

      const updatedData = [
        {
          id: 0,
          pharmacyName: activePharmacy.name,
          address: `${activePharmacy.address.zipcode} ${activePharmacy.address.address_name}`,
          appointmentDuration: `${format(appointmentStartDate, 'HH:mm')}-${format(appointmentEndDate, 'HH:mm')}`,
          toNextAppointment: nextLeg ? `${nextLeg.distance.text} / ${nextLeg.duration.text}` : '',
          trainingDurationInSeconds: selectedOrderItems ? trainingDurationInSeconds : 0,
          appointmentStartDate,
          rank: getAppointmentRanking(appointmentStartDate),
        },
      ]

      prevDataRef.current = updatedData

      return updatedData
    }

    return prevDataRef.current
  }, [sortedAppointmentsToday, projects, filteredLegs, activePharmacy, selectedTime, selectedOrderItems, selectedDay, shouldUpdateDataItem])

  useEffect(() => {
    if (dataItems.length >= 1 && routeResponse) {
      setShouldUpdateDataItem(false)
    }
  }, [dataItems])

  const tourStartTime = useMemo(() => {
    const timeOfFirstAppointment = dataItems[0]?.appointmentStartDate
    if (timeOfFirstAppointment) {
      return format(subSeconds(timeOfFirstAppointment, durationToFirstAppountment), 'HH:mm')
    }
    return '-'
  }, [dataItems, durationToFirstAppountment])

  const tourEndTime = useMemo(() => {
    const lastTrainingDuration = dataItems[dataItems.length - 1]?.trainingDurationInSeconds
    const durationToHome = filteredLegs[filteredLegs.length - 1]?.duration?.value ?? 0
    const timeOfLastAppointment = dataItems[dataItems.length - 1]?.appointmentStartDate
    if (timeOfLastAppointment) {
      const startDate = addSeconds(timeOfLastAppointment, durationToHome + lastTrainingDuration)
      return format(startDate, 'HH:mm')
    }
    return '-'
  }, [filteredLegs, dataItems])

  const fitsJourney = useCallback(
    (index: number) => {
      if (index === 0) {
        return ITrafficColors.GREEN
      }

      const prevMeeting = dataItems[index - 1]
      const travelTime = filteredLegs[index]?.duration?.value ?? 0
      const timeNeeded = addSeconds(prevMeeting.appointmentStartDate, travelTime + (prevMeeting.trainingDurationInSeconds ?? 0))
      const startingDate = dataItems[index].appointmentStartDate
      const timeNeededPlus15Minutes = addSeconds(timeNeeded, 60 * 15)
      const timeNeededPlus5Minutes = addSeconds(timeNeeded, 60 * 5)

      if (isAfter(startingDate, timeNeededPlus15Minutes) || isEqual(startingDate, timeNeededPlus15Minutes)) {
        return ITrafficColors.GREEN
      }
      if (isAfter(startingDate, timeNeededPlus5Minutes) || isEqual(startingDate, timeNeededPlus5Minutes)) {
        return ITrafficColors.YELLOW
      }

      return ITrafficColors.RED
    },
    [filteredLegs, dataItems],
  )

  if (!routeResponse) {
    return null
  }

  return (
    <Fade inProp={showSelectedDayOverview}>
      <RouteModal
        title={`Route für den ${selectedDay ? formattedDateString(selectedDay) : formattedDateString(selectedTime)}`}
        onClose={() => {
          setSelectedDay(undefined)
          setRouteResponse(null)
          setRouteForAppointment(false)
          setSelectedTime(new Date())
          handleSidebarToCalender()
          setShouldUpdateDataItem(true)
          setSelectedPharmacy(undefined)
        }}
        viewPortWidth={width}
        headerAdditionalButtons={
          <IconButton
            kind={IconButtonKind.custom}
            additionalCss="text-gray-600 flex flex-col justify-center mr-2 mt-2.5"
            onClick={toggleShowSelectedDayOverview}
            icon={<div className="h-0.5 bg-white w-4" />}
          />
        }
      >
        <RouteModalItem>
          <div className="w-8 flex flex-wrap my-3">
            <div className="w-full h-8 mt-2 font-bold  items-center text-center text-white pl-0.5">
              <MapMarkerHomeIcon />
            </div>
            <RouteColorMarker index={0} />
          </div>

          <div className="w-full pl-2 relative">
            <span className="block mt-3 font-bold">{`Tourstart: ${tourStartTime}`}</span>
            <span className="block py-3 text-white">{`${distanceDataToFirstAppointment.distance.text} / ${distanceDataToFirstAppointment.duration.text}`}</span>
          </div>
        </RouteModalItem>

        {dataItems.map((appointment) => (
          <RouteModalItem key={appointment.id}>
            <div className="w-8 flex flex-wrap my-2">
              <div className="w-full h-10 font-bold justify-center items-center text-center">
                <MapMarker customCSS="h-auto mx-auto w-6" color={MapMarkerColor.transparent} letter={''} />
              </div>
              <RouteColorMarker index={appointment.id + 1} />
            </div>
            <div className="w-full pl-2 relative">
              <span className="block mt-2">
                {appointment.pharmacyName}
                <br />
                {appointment.address}
                <br />
                <span className="block mt-2">{appointment.appointmentDuration}</span>
              </span>
              <span className="block py-3">{appointment.toNextAppointment}</span>
              <TrafficLight color={fitsJourney(appointment.id)} />
            </div>
          </RouteModalItem>
        ))}

        <RouteModalItem>
          <div className="w-8 flex flex-wrap">
            <div className="w-8 h-8 mt-3 font-bold justify-center items-center text-center text-white pl-0.5">
              <MapMarkerHomeIcon />
            </div>
          </div>
          <div className="w-full pl-2 relative">
            <span className="block mt-3 font-bold">{`Tourende: ${tourEndTime}`}</span>
          </div>
        </RouteModalItem>
      </RouteModal>
    </Fade>
  )
}
